<template>
<div>
    <div>
        nash地址:<el-input style="width: 60%" v-model="url1"></el-input>
    </div>
    <div>
        nash1地址:<el-input style="width: 60%" v-model="url2"></el-input>
    </div>
    <div>
        {{finalUrl}}
    </div>
    <div>
        <el-button @click="doGen">生成地址</el-button>
        <el-button @click="copyMpAddress">复制</el-button>
    </div>
</div>
</template>

<script>
export default {
    name: "MpUrlGen",
    data() {
        return {
            url1: '',
            url2: '',
            finalUrl: ''
        }
    },
    methods:{
        doGen() {
            if (!this.url1 || !this.url2) {
                this.$message.error('请输入地址')
                return
            }
            let site1='nash'
            let site2='nash1'
            if (process.env.NODE_ENV !== 'production') {
                site1 = 'siteadmin'
                site2 = 'test'
            }
            const text = 'pages/main/nhMain?site=' + site1 + '&'+site1+'=' + encodeURIComponent(this.url1) + '&' + site2+'=' + encodeURIComponent(this.url2)
            this.finalUrl = text
        },
        copyMpAddress() {

            this.$copyText(this.finalUrl).then((e) => {
                this.$message({
                    message: "复制成功",
                    type: "success"
                });
                console.log(e)
            }, (e) => {
                this.$message({
                    message: "无法复制",
                    type: "error"
                });
                console.log(e)
            })
        },
    }
}
</script>

<style scoped>

</style>